import React from "react"
import {
  Container,
  Button,
  Col,
  Media,
  Image,
  Row,
  Dropdown,
} from "react-bootstrap"
import USMap from "../components/Extras/USMap/USMap.component"
import "./styles/mapPage.style.scss"
import Footer from "../components/Layout/Footer"
import NavBar from "../components/Layout/navBar/NavBar"
import { StaticImage } from "gatsby-plugin-image"

let validatedState = ""

function updateStateDropDown(e) {
  document.getElementById("dropdown-basic-wl").innerHTML = e.split("#", 2)[1]
  validatedState = e.split("#", 2)[1]
}

function redirection() {
  let redirectURL = "https://application.pavecredit.com/?st=" + validatedState
  /*
  let endpoint = window.location.href

  if (endpoint.indexOf("dev.") >= 0) {
    redirectURL = "https://application.pavecredit.com/"
  } */
  if (validatedState) {
    window.location.href = redirectURL
  }
}

const MapPage = () => {
  return (
    <>
      <NavBar />
      <Container fluid className="home-container p-0 map-page ">
        <Container fluid className="top-home">
          <Container fluid="xl" className="home-slider text-center pt-5">
            <h1 className="text-center h1-header text-white pt-5 pb-3">
              {" "}
              Where We Lend{" "}
            </h1>{" "}
            <Row className="mt-4 mb-5">
              <Col className="text-md-right text-center mb-3 mb-md-0">
                <Dropdown onSelect={updateStateDropDown}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic-wl">
                    State Selection{" "}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#AL"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Alabama
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#AZ"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Arizona
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#CA"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      California
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#CO"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Colorado
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#DE"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Delaware
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#DC"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      District of Columbia
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#FL"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Florida
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#HI"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Hawaii
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#ID"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Idaho
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#IL"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Illinois
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#IN"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Indiana
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#IA"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Iowa
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#KS"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Kansas
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#KY"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Kentucky
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#LA"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Louisiana
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#ME"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Maine
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#MI"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Michigan
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#MN"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Minnesota
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#MS"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Mississippi
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#MO"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Missouri
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#MT"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Montana
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#NE"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Nebraska
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#NV"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Nevada
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#NM"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      New Mexico
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#ND"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      North Dakota
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#OH"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Ohio
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#OK"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Oklahoma
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#OR"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Oregon
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#RI"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Rhode Island
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#SC"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      South Carolina
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#TN"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Tennessee
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#UT"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Utah
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#WA"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Washington
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#WI"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Wisconsin
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#WY"
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      Wyoming
                    </Dropdown.Item>
                  </Dropdown.Menu>{" "}
                </Dropdown>{" "}
              </Col>{" "}
              <Col className="text-md-left text-center" md={6}>
                <Button
                  variant="primary"
                  className="w-50 py-2"
                  onClick={redirection}
                >
                  Start Application{" "}
                </Button>{" "}
              </Col>{" "}
            </Row>{" "}
            <USMap />
            <StaticImage
              className="media-img mb-5"
              src="../images/map_legend_v01.png"
              alt="Jump Mobile App"
              height={50}
            />
          </Container>{" "}
        </Container>{" "}
      </Container>{" "}
      <Footer />
    </>
  )
}

export default MapPage
